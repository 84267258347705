<template>
  <v-card tile flat>
    <v-tabs v-model="termTypeTabs" background-color="primary" dark show-arrows>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab v-for="termtype in termTypes" :key="termtype.name" :href="`#${termtype.name}`">
        <v-icon left small class="mr-1">
          {{ utils.getTermTypeIcon(termtype.name) }}
        </v-icon>
        {{ termtype.name }}S
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="termTypeTabs">
      <v-tab-item v-for="termtype in termTypes" :key="termtype.name" :value="termtype.name">
        <TermListing :term-type-id="termtype.termtypeid"/>
      </v-tab-item>
    </v-tabs-items>

  </v-card>

</template>
<script>
import TermListing from '@/components/terms/TermListing'
import { mapState } from 'vuex'
import utils from '@/helpers/utils'

export default {
  name: 'TermsLanding',
  components: {
    TermListing
  },
  data: () => ({
    utils
  }),
  computed: {
    ...mapState('terms', ['termTypes']),
    termTypeTabs: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  async created() {
    if (this.termTypes && this.termTypes.length) { return }
    await this.$store.dispatch('terms/getTermTypes')
  }
}
</script>