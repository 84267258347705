<template>
  <v-container fluid class="pa-0">
    <v-row align="center" class="list mx-auto">

      <v-col cols="12" class="pl-0 pr-0 pb-0 ma-0">
        <v-row class="ma-2">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            @keydown.enter="onSearch"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per Page"
            @change="handlePageSizeChange"
          ></v-select>
          <v-spacer></v-spacer>
          <v-pagination
            v-if="totalPages"
            v-model="page"
            :length="totalPages"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          ></v-pagination>
        </v-row>
      </v-col>

      <v-col cols="12" class="mb-2">
        <v-card tile>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
            disable-pagination
            :hide-default-footer="true"
            mobile-breakpoint="0"
          >
            <template #item.title="{ item }">
              <router-link 
                :to="{ name: 'term_home', params: { termid: item.id } }">
                {{ item.title }}
              </router-link>
            </template>
          </v-data-table>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocAdmin from '@/store/data'

export default {
  name: 'TermListing',
  props: {
    termTypeId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    loading: true,
    options: {},
    items: [],
    search: '',
    totalResults: -1,
    headers: [
      { text: 'Id', align: 'start', sortable: false, value: 'id' },
      { text: 'Title', sortable: false, value: 'title' }
    ],
    page: 1,
    totalPages: 0,
    pageSize: localStorage.getItem('dataTablePageSize') ? parseInt(localStorage.getItem('dataTablePageSize')) : 10,
    pageSizes: [10, 20, 30, 40, 50, 100],
    offset: 0
  }),
  created() {
    this.retrieveData()
  },
  methods: {
    getRequestParams(search, page, pageSize, offset) {
      return {
        search: search ? search : null,
        page: page ? page - 1 : null,
        offset: page && pageSize ? (page - 1) * pageSize : 0,
        limit: pageSize ? pageSize : null
      }
    },
    async retrieveData() {
      const params = this.getRequestParams(
        this.search,
        this.page,
        this.pageSize,
        this.offset
      )

      params.termtypeid = this.termTypeId
      
      const d = await DocAdmin.terms.list({params: params})
      this.setData(d.data)
      this.loading = false
    },
    handlePageChange(value) {
      // localStorage.setItem('dataTablePage', parseInt(value))
      this.page = value
      this.retrieveData()
    },
    handlePageSizeChange(size) {
      localStorage.setItem('dataTablePageSize', parseInt(size))
      this.pageSize = size
      this.page = 1
      this.retrieveData()
    },
    getDisplayDocument(term) {
      return {
        id: term.termid,
        title: term.englishtitle
      }
    },
    setData(data) {
      this.items = data.results.map(this.getDisplayDocument)
      this.totalPages = Math.round(data.count / this.pageSize)
    },
    onSearch(event) {
      event.preventDefault()
      this.retrieveData()
    }
  }
}
</script>